<template>
  <div class="client-create">
    <b-row>
      <b-col>
        <b-card>
          <validation-observer ref="clientValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-row>
                <b-col md="3">
                  <b-form-group label="Номер телефона">
                    <validation-provider
                      #default="{ errors }"
                      name="Номер телефона"
                      rules="phone|required"
                    >
                      <b-form-input
                        v-model="form.username"
                        placeholder="+375000000000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Имя">
                    <b-form-input v-model="form.first_name" placeholder="Имя" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Фамилия">
                    <b-form-input
                      v-model="form.last_name"
                      placeholder="Фамилия"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Пол">
                    <b-form-select
                      v-model="form.gender"
                      :options="['М', 'Ж']"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        v-model="form.email"
                        placeholder="Email"
                        type="email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Пароль">
                    <b-form-input
                      v-model="form.password"
                      placeholder="Пароль"
                      type="password"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <b-button variant="primary" @click="validationForm">
                    Создать
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email, phone } from '@validations'

export default {
  name: 'ClientCreate',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {},
      required,
      email,
      phone,
    }
  },
  methods: {
    async validationForm() {
      this.$refs.clientValidation.validate().then(async (success) => {
        if (success) {
          await this.$http
            .post('admin/users/', this.form)
            .then(async (response) => {
              await this.$router.push({ path: `/clients/${response.data.id}` })
            })
            .catch(() => {
              this.$bvToast.toast('Проверьте поля', {
                title: 'Ошибка',
                variant: 'danger',
              })
            })
        }
      })
    },
  },
}
</script>
